const commonMethods = {
  methods: {
    imgUrl(url) {
      return process.env.VUE_APP_API_URL + url;
    },
    handleNewLine(str) {
      return str ? str.toString().replace(/(\n|\r\n)/g, "<br>") : "";
    },
    handleSpace(str) {
      return str ? str.toString().replace("<br />", " ") : " ";
    }
  }
};

export {commonMethods};
